f<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="container mt-80p" v-if="$store.state.cart.totalProductCount === 0">
      <div class="alert alert-warning" role="alert">
        {{ $t('cart-is-empty-please-add-products') }}
      </div>
    </div>
    <div id="main-container" class="container" v-else>
      <div class="row">
        <div class="col-md-6 order-2 order-md-1 delivery-details-container">
          <div class="delivery-details-title">{{ $t('delivery-details') }}</div>
          <div class="client-info-wrapper">
            <div class="wrapper-title"> {{ $t('buyer-information') }}</div>
            <div class="point-of-contact"> {{ $t('point-of-contact') }}</div>
            <div class="row input-container">
              <div class="col-md-4 input-item">
                <InputField v-model="order.name" :content="order.name" :inputtype="'text'" :label="$t('your-name')"
                            :error="formErrors.includes('name')"/>
              </div>
              <div class="col-md-4 input-item">
                <InputField v-model="order.phone" :content="order.phone" :inputtype="'text'" :label="$t('phone')"
                            :error="formErrors.includes('phone')"/>
              </div>
              <div class="col-md-4 input-item">
                <InputField v-model="order.email" :content="order.email" :inputtype="'email'" :label="'Email'"
                            :error="formErrors.includes('email')"/>
              </div>
            </div>
          </div>
          <div class="delivery-details-wrapper">
            <div class="wrapper-title">{{ $t('delivery-details') }}</div>
            <div class="delivery-type">
              <div class="d-flex">
                <div class="d-flex radio-checkbox-options">
                  <RadioButton v-model="order.deliveryType" :id="'address'" :name="'deliveryType'" :value="1" :checked="true"/>
                  <div v-bind:class="{'option-select-label': true, 'form-label-err': formErrors.includes('deliveryType')}">
                    {{ $t('delivery-to') }} {{ $t('address') }}
                  </div>
                </div>
                <div class="d-flex radio-checkbox-options" v-if="false">
                  <RadioButton v-model="order.deliveryType" :id="'office'" :name="'deliveryType'" :value="2"
                               :error="formErrors.includes('deliveryType')"/>
                  <div v-bind:class="{'option-select-label': true, 'form-label-err': formErrors.includes('deliveryType')}">
                    {{ $t('delivery-to') }} {{ $t('courier-office') }}
                  </div>
                </div>
              </div>
              <div class="row input-container">
                <div class="col-md-6 input-item">
                  <Dropdown :label="$t('please-select')" v-model="order.deliveryCity" :options="citiesOptions"
                            v-if="citiesOptions" :error="formErrors.includes('deliveryCity')"/>
                </div>
                <div class="col-md-6 input-item">
                  <InputField v-model="order.deliveryAddress" :content="order.deliveryAddress" :inputtype="'text'"
                              :label="$t('address')" :error="formErrors.includes('deliveryAddress')"/>
                </div>
              </div>
            </div>
            <div class="delivery-information">
              <div class="info-item-subtitle"> {{ $t('delivery-information') }}</div>
              <div class="vendor-delivery-info" v-for="vendor in vendor">
                <div class="order-from-vendor-subtitle"> {{ $t('order-from-vendor') }}: {{ vendor.name }}</div>
                <div> {{ $t('expected-delivery-date') }}: {{ vendor.vendor_delivery_info }}</div>
                <div> {{ $t('delivery-price') }}:
                  <span> {{ vendor.vendor_delivery_price }} {{ $t('currency-type') }}</span>
                </div>
              </div>
            </div>
            <div class="invoice-details">
              <div class="info-item-subtitle"> {{ $t('invoice-details') }}</div>
              <div class="d-flex want-invoice">
                <div class="d-flex radio-checkbox-options">
                  <RadioButton v-model="order.isInvoiceRequired" :id="'invoiceIsRequired'" :name="'invoiceRequired'"
                               :value="1"/>
                  <div v-bind:class="{'option-select-label': true, 'form-label-err': formErrors.includes('isInvoiceRequired')}">
                    {{ $t('want-invoice') }}
                  </div>
                </div>
                <div class="d-flex radio-checkbox-options">
                  <RadioButton v-model="order.isInvoiceRequired" :id="'invoiceIsNotRequired'" :name="'invoiceRequired'"
                               :value="0"/>
                  <div v-bind:class="{'option-select-label': true, 'form-label-err': formErrors.includes('isInvoiceRequired')}">
                    {{ $t('dont-want-invoice') }}
                  </div>
                </div>
              </div>
              <div class="want-invoice-wrapper" v-if="order.isInvoiceRequired">
                <div class="info-item-subtitle"> {{ $t('issue-invoice-to') }}</div>
                <div class="want-invoice d-flex">
                  <div class="d-flex radio-checkbox-options">
                    <RadioButton v-model="invoiceType" :id="'entityTypePerson'" :name="'entityType'"
                                 :value="'person'"/>
                    <div class="option-select-label"> {{ $t('person') }}</div>
                  </div>
                  <div class="d-flex radio-checkbox-options">
                    <RadioButton v-model="invoiceType" :id="'entityTypeCompany'" :name="'entityType'"
                                 :value="'company'"/>
                    <div class="option-select-label"> {{ $t('company') }}</div>
                  </div>
                </div>
                <div class="row" v-if="invoiceType === 'company'">
                  <div class="col-md-6 input-item">
                    <InputField v-model="order.invoiceDetails.copmanyName" :content="order.invoiceDetails.copmanyName"
                                :inputtype="'text'" :label="$t('company-name')"/>
                  </div>
                  <div class="col-md-6 input-item">
                    <InputField v-model="order.invoiceDetails.companyAddress"
                                :content="order.invoiceDetails.companyAddress" :inputtype="'text'"
                                :label="$t('company-address')"/>
                  </div>
                  <div class="col-md-6 input-item">
                    <InputField v-model="order.invoiceDetails.eik" :content="order.invoiceDetails.eik" :inputtype="'text'"
                                :label="$t('eic-bulstat')"/>
                  </div>
                  <div class="col-md-6 input-item">
                    <InputField v-model="order.invoiceDetails.accountablePerson"
                                :content="order.invoiceDetails.accountablePerson" :inputtype="'text'" :label="$t('mol')"/>
                  </div>
                  <div class="col-md-6 input-item">
                    <InputField v-model="order.invoiceDetails.companyId" :content="order.invoiceDetails.companyId"
                                :inputtype="'text'" :label="$t('vat-number')"/>
                  </div>
                </div>
                <div class="row" v-if="invoiceType === 'person'">
                  <div class="col-md-6 input-item">
                    <InputField v-model="order.invoiceDetails.copmanyName" :content="order.invoiceDetails.copmanyName"
                                :inputtype="'text'" :label="$t('first-name')"/>
                  </div>
                  <div class="col-md-6 input-item">
                    <InputField v-model="order.invoiceDetails.companyAddress"
                                :content="order.invoiceDetails.companyAddress" :inputtype="'text'"
                                :label="$t('family-name')"/>
                  </div>
                  <div class="col-md-6 input-item">
                    <InputField v-model="order.invoiceDetails.eik" :content="order.invoiceDetails.eik" :inputtype="'text'"
                                :label="$t('egn')"/>
                  </div>
                  <div class="col-md-6 input-item">
                    <InputField v-model="order.invoiceDetails.accountablePerson"
                                :content="order.invoiceDetails.accountablePerson" :inputtype="'text'" :label="$t('address')"/>
                  </div>
                  <div class="col-md-6 input-item">
                    <InputField v-model="order.invoiceDetails.companyId" :content="order.invoiceDetails.companyId"
                                :inputtype="'text'" :label="$t('post-code')"/>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="payment-methods-wrapper">
            <div class="wrapper-title"> {{ $t('payment-method') }}</div>
            <div class="payment-subtitle"> {{ $t('select-payment-method') }}</div>
            <div v-bind:class="{'payment-method d-flex': true, 'form-border-error': formErrors.includes('paymentType')}">
              <RadioButton v-model="order.paymentType" :id="'paymentTypeCard'" :name="'paymentType'" :value="1"/>
              <div class="option-select-label"> {{ $t('card-payment') }}</div>
              <div class="ms-auto me-1">
                <svg width="36" height="18" viewBox="0 0 36 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="35.6" height="17.8" rx="1.48333" fill="#FBFAF8"/>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M23.8572 5.52396C23.4291 5.36381 22.7582 5.19171 21.9199 5.19171C19.7841 5.19171 18.2791 6.26397 18.2665 7.79967C18.2548 8.93611 19.3405 9.56883 20.1608 9.94765C21.0018 10.3343 21.285 10.5816 21.2806 10.9274C21.2752 11.4572 20.6091 11.6992 19.9878 11.6992C19.1224 11.6992 18.663 11.5799 17.953 11.2848L17.6742 11.159L17.3708 12.9284C17.8762 13.1487 18.8092 13.3403 19.7788 13.35C22.0513 13.35 23.5266 12.2904 23.543 10.6499C23.5509 9.75069 22.9754 9.06637 21.7286 8.50287C20.9725 8.13695 20.51 7.89249 20.515 7.52248C20.515 7.19464 20.9062 6.84257 21.7523 6.84257C22.4601 6.83156 22.9716 6.98541 23.3703 7.14587L23.5645 7.23649L23.8572 5.52396ZM13.8566 13.2327L15.2081 5.32955H17.3695L16.0173 13.2327H13.8566ZM27.7308 5.33725H29.4013L31.1507 13.2348H29.1446C29.1446 13.2348 28.9466 12.3274 28.8819 12.0508C28.7147 12.0508 28.0165 12.0499 27.3602 12.0491C26.7785 12.0484 26.2299 12.0477 26.1134 12.0477C26.0294 12.2613 25.6585 13.2348 25.6585 13.2348H23.3881L26.5983 5.99262C26.8263 5.47757 27.213 5.33725 27.7308 5.33725ZM26.7349 10.4311C26.9139 9.97584 27.5965 8.22147 27.5965 8.22147C27.5902 8.23175 27.6322 8.12306 27.6895 7.97462L27.6896 7.97441L27.6897 7.974C27.7499 7.81824 27.827 7.6189 27.8828 7.4673L28.0287 8.14879C28.0287 8.14879 28.4429 10.035 28.5297 10.4311H26.7349ZM9.92834 10.7245L12.0443 5.33527H14.3338L10.9311 13.223L8.64369 13.2262L6.70898 6.31376C8.08109 6.9984 9.30891 8.3664 9.70261 9.62933L9.92834 10.7245Z"
                        fill="#1A1F71"/>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M7.96478 5.33002H4.47864L4.45117 5.49425C7.16318 6.149 8.95771 7.72969 9.70248 9.62942L8.94445 5.99703C8.81374 5.49646 8.43393 5.34732 7.96478 5.33002Z"
                        fill="#F9A51A"/>
                </svg>
              </div>
              <div>
                <svg width="36" height="18" viewBox="0 0 36 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="35.6" height="17.8" rx="1.48333" fill="#FBFAF8"/>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M22.1076 15.575C25.8734 15.575 28.9262 12.5865 28.9262 8.90004C28.9262 5.21354 25.8734 2.22504 22.1076 2.22504C18.3418 2.22504 15.2891 5.21354 15.2891 8.90004C15.2891 12.5865 18.3418 15.575 22.1076 15.575Z"
                        fill="#F79E1B"/>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M13.4943 15.575C17.2601 15.575 20.3129 12.5865 20.3129 8.90004C20.3129 5.21354 17.2601 2.22504 13.4943 2.22504C9.72855 2.22504 6.67578 5.21354 6.67578 8.90004C6.67578 12.5865 9.72855 15.575 13.4943 15.575Z"
                        fill="#EB001B"/>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M17.801 3.72467C16.268 4.94867 15.2891 6.81243 15.2891 8.90003C15.2891 10.9876 16.268 12.8514 17.801 14.0754C19.334 12.8514 20.313 10.9876 20.313 8.90003C20.313 6.81243 19.334 4.94867 17.801 3.72467Z"
                        fill="#FF5F00"/>
                </svg>
              </div>
            </div>
            <div v-if="false" v-bind:class="{'payment-method d-flex': true, 'form-border-error': formErrors.includes('paymentType')}">
              <RadioButton v-model="order.paymentType" :id="'paymentTypeCash'" :name="'paymentType'" :value="2"/>
              <div class="option-select-label"> {{ $t('payment-on-delivery') }}</div>
            </div>
          </div>
          <div class="additional-info">
            <div class="wrapper-title">{{ $t('additional-requirements') }}</div>
            <div class="">
              <div class="d-flex">
                <div class="d-flex radio-checkbox-options">
                  <RadioButton v-model="order.hasAdditionalInfo" :id="'hasAdditionalInfo'" :name="'hasAdditionalInfo'"
                               :value="1"/>
                  <div v-bind:class="{'option-select-label': true, 'form-label-err': formErrors.includes('hasAdditionalInfo')}">
                    {{ $t('yes') }}
                  </div>
                </div>
                <div class="d-flex radio-checkbox-options">
                  <RadioButton v-model="order.hasAdditionalInfo" :id="'hasNotAdditionalInfo'" :name="'hasAdditionalInfo'"
                               :value="0"/>
                  <div v-bind:class="{'option-select-label': true, 'form-label-err': formErrors.includes('hasAdditionalInfo')}">
                    {{ $t('no') }}
                  </div>
                </div>
              </div>
              <div v-if="order.hasAdditionalInfo">
                <TextArea v-model="order.additionalInfo" :content="order.additionalInfo"/>
              </div>

            </div>
            <div class="d-flex radio-checkbox-options mt-4">
              <CheckBox v-model="termsAgreed"/>
              <div class="option-select-label" v-bind:class="{'text-danger': formErrors.includes('termsAgreed')}">{{ $t('agree-terms') }}</div>
            </div>
            <div class="d-flex radio-checkbox-options">
              <CheckBox v-model="cancellationAgreed"/>
              <div class="option-select-label" v-bind:class="{'text-danger': formErrors.includes('cancellationAgreed')}">{{ $t('cancellation-option-acknowledge') }}</div>
            </div>

          </div>
          <div class="submit-order-button col-md-12 mt-2">
            <Button @click="sendOrder"
                    :text="$t('place-order')"
                    :theme="orderValidated ? 'solid-green' : 'solid-grey'"
                    :size="'medium'"/>
          </div>
        </div>
        <div class="col-md-6 order-1 order-md-2 cart-items-container">
          <div class="cart-items-title mb-2">{{ $t('your-purchases') }}</div>
          <div v-if="products" v-for="item in products">
            <div class="one-vendor-cart-wrapper" v-if="Object.keys(item.products).length > 0">
              <div class="wrapper-title d-md-flex"> {{ $t('products-offered-by') }}
                <span> {{ item.vendor.name }}</span>
                <div class="d-flex ms-auto conditions">
                  <InfoIcon/>
                  <div class="ms-1"> {{ $t('conditions-for-delivery-warranty-returns') }}</div>
                </div>
              </div>
              <div class="cart-item" v-for="product in item.products">
                <CartItem :product="product"/>
              </div>
              <div class="row">
                <div class="col-md-8 free-delivery-title">
                  <TruckIcon/>
                  {{ $t('orders-above') }} {{ item.freeDeliveryAmount }} {{ $t('currency-type') }}
                  {{ $t('get-free-delivery') }}
                </div>
                <div class="col-md-4 breakdown-per-vendor">
                  <div class="d-flex">
                    <div class="me-auto"> {{ $t('products') }}:</div>
                    <div class=""> {{ Object.keys(item.products).length }}</div>
                  </div>
                  <div class="d-flex">
                    <div class="me-auto"> {{ $t('taxes') }}:</div>
                    <div class=""> {{ item.totalTaxes.toFixed(2) }} {{ $t('bgn') }}</div>
                  </div>
                  <div class="d-flex total-per-vendor">
                    <div class="me-auto"> {{ $t('total') }}:</div>
                    <div class="total-amount-per-vendor"> {{ item.totalAmount }} {{ $t('bgn') }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="order-total-breakdown">
            <div class="order-breakdown-container row">
              <div class="order-breakdown-title">{{ $t('total-for-the-order') }}</div>
              <div class="all-products-amount d-flex">
                <div class="me-auto">{{ $t('all-products') }}</div>
                <div>{{ $store.state.cart.totalProductCount }} {{ $t('count') }}.</div>
              </div>
              <div class="delivery-amount d-flex">
                <div class="me-auto">{{ $t('delivery-price') }}</div>
                <div>{{ $store.state.cart.totalDeliveryAmount }} {{ $t('bgn') }}.</div>
              </div>
              <div class="total-amount-container d-flex">
                <div class="me-auto">{{ $t('total-price') }}</div>
                <div class="total-amount">{{ $store.state.cart.totalOrderAmount }} {{ $t('bgn') }}.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

